import styled from 'styled-components';
import { device } from '../../theme';

const PagesSectionWrapper = styled.section`
  background-color: ${(props) => (props.bgColor ? props.bgColor : 'white')};
  background-image: url(${(props) => props.bgUrl});
  background-position: center left;
  background-repeat: ${(props) => (props.repeatX ? 'repeat-x' : 'no-repeat')};
  display: ${(props) => (props.hideSection ? 'none' : 'block')};
  position: relative;
  @media ${device.laptop} {
    display: ${(props) => (props.hideSection ? 'block' : 'block')};
  }
`;

export { PagesSectionWrapper };
