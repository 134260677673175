import React from 'react';
import Layout from '../components/Layout';
import { colors } from '../theme/constants';
import { Container } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import IntroPages from '../components/common/IntroPages';
import { SectionWrapper } from '../components/common/SectionWrapper';
import { Grid, Cell } from '../theme';
import ContentWrapper from '../components/common/ContentWrapper';
import ServiceBox from '../components/common/ServiceBox';

const GoalsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const GoalsItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: ${(props) =>
    props.positionStart ? 'flex-start' : 'flex-end'};
  padding-bottom: 2rem;
`;

const HomePage = (props) => {
  return (
    <Layout>
      <IntroPages
        title={'About Us'}
        menuItemOne={'home'}
        menuItemTwo={'about us'}
      ></IntroPages>
      <SectionWrapper>
        <Container>
          <Grid columnGap={'6rem'} rows={1} columns={2}>
            <Cell>
              <ContentWrapper
                fontColor={colors.blue.mainBlue}
                colorFont={colors.blue.navBlue}
                colorBlue={colors.blue.textBlue}
                pageLabel={'// WHAT WE OFFER'}
                title={'Your Partner for Software Innovation'}
                contentOne={
                  'Softiware is passionate about technology. Our clients come to us seeking customized, skillfully crafted business applications. Our longevity is due to the skill and efficiency of our team of senior software engineers, project managers, and devops staff.'
                }
              />
            </Cell>
            <Cell>
              <GoalsContainer>
                <GoalsItemsWrapper positionStart={props.positionStart}>
                  <ServiceBox
                    bgUrl={'/images/bg-iservice-box1.jpg'}
                    header={'Web Development'}
                    details={
                      'We involve our clients at every stage of the development process.'
                    }
                  ></ServiceBox>
                  <ServiceBox
                    bgUrl={'/images/bg-iservice-box1.jpg'}
                    header={'Web Development'}
                    details={
                      'We involve our clients at every stage of the development process.'
                    }
                  ></ServiceBox>
                </GoalsItemsWrapper>
                <GoalsItemsWrapper>
                  <ServiceBox
                    bgUrl={'/images/bg-iservice-box1.jpg'}
                    header={'Web Development'}
                    details={
                      'We involve our clients at every stage of the development process.'
                    }
                  ></ServiceBox>
                  <ServiceBox
                    bgUrl={'/images/bg-iservice-box1.jpg'}
                    header={'Web Development'}
                    details={
                      'We involve our clients at every stage of the development process.'
                    }
                  ></ServiceBox>
                </GoalsItemsWrapper>
              </GoalsContainer>
            </Cell>
          </Grid>
        </Container>
      </SectionWrapper>
    </Layout>
  );
};

export default HomePage;
