import React from 'react';
import { PagesSectionWrapper } from './PagesSectionWrapper';
import { colors } from '../../theme/constants';
import { Container } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { device } from '../../theme';

const Title = styled.div`
  color: ${colors.yellow.mainDarkYellow};
  font-weight: 600;
  background-size: cover;
  font-size: 3rem;
  @media ${device.tablet} {
    font-size: 5rem;
    min-height: 350px;
    font-weight: 800;
    display: flex;
    align-items: center;
  }
`;

const NavContainer = styled.div`
  display: block;
  padding: 6rem 0;
  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }
`;

const BackLink = styled.ul`
  margin-bottom: 0;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  list-style: none;
  padding-left: 0;
  @media ${device.tablet} {
    font-size: 1.4rem;
    font-weight: 800;
  }
`;

const BackLinkItem = styled.li`
  display: inline-block;
  color: #fcd669;
  :nth-child(2) {
    :before {
      content: '';
      font-size: 7px;
      color: #43baff;
      margin: -3px 8px 0;
      display: inline-block;
      vertical-align: middle;
    }
  }
`;

const BackLinkItemLink = styled.a`
  color: white;
  text-decoration: none;
  background-color: transparent;
`;
const HomePage = (props) => {
  return (
    <PagesSectionWrapper
      // bgUrl={'/images/bg-art-1.png'}
      bgColor={colors.blue.pageHeaderBlue}
    >
      <Container>
        <NavContainer>
          <Title>{props.title}</Title>
          <BackLink>
            <BackLinkItem>
              <BackLinkItemLink href="#">{props.menuItemOne}</BackLinkItemLink>
            </BackLinkItem>
            <BackLinkItem>{props.menuItemTwo}</BackLinkItem>
          </BackLink>
        </NavContainer>
      </Container>
    </PagesSectionWrapper>
  );
};

export default HomePage;
